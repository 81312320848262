



























































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import { createWorkflow } from '@/api/consoleApi/workflows'
import { getLists } from '@/api/consoleApi/recipients'
import { UserModule } from '@/store/modules/user'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'

@Component({
    name: 'CreateWorkflowModal',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsLoader,
        VsActionCard,
    },
})
export default class extends Vue {
    private open = false
    private loading = false
    listId: any = null
    selectedList: any = null
    filterType = 'list'
    search = ''
    flows = []
    lists: any[] = []
    $refs: any

    get user () {
        return UserModule.user
    }

    get availableFlows () {
        return [
            {
                title: this.$t('workflows.workflowCard.triggerName.welcomeMessage.title'),
                description: 'Invia una serie di messaggi quando un contatto si iscrive ad una lista',
                type: 'list',
                triggerName: 'welcomeMessage',

            },
            {
                title: this.$t('workflows.workflowCard.triggerName.updateSubscriber.title'),
                description: 'Invia una serie di messaggi alla variazione dei campi personalizzati di un contatto',
                type: 'list',
                triggerName: 'updateSubscriber',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.flowEnd.title'),
                description: 'Invia una serie di messaggi ai contatti che hanno completato o sono usciti da un\'altra Marketing Automation',
                type: 'workflow',
                triggerName: 'flowEnd',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.campaignActivity.title'),
                description: 'Invia una serie di messaggi quando un contatto fa un\'attività di campagna come click o aperture',
                type: 'campaign',
                triggerName: 'campaignActivity',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.onDate.title'),
                description: 'Invia una serie di messaggi basati su un campo personalizzato di tipo data del contatto oppure sulla data di iscrizione del contatto in lista',
                type: 'date',
                triggerName: 'onDate',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.webSite.title'),
                description: 'Invia una serie di messaggi quando un contatto fa una determinata azione sul tuo sito web tracciata tramite il Goal Tracking',
                type: 'integration',
                triggerName: 'webSite',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.ecommerceOrderCompleted.title'),
                description: 'Invia una serie di messaggi quando viene concluso un ordine dal tuo negozio online',
                type: 'ecommerce',
                triggerName: 'ecommerceOrderCompleted',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.ecommerceProductPurchased.title'),
                description: 'Invia una serie di messaggi quando viene acquistato uno specifico prodotto',
                type: 'ecommerce',
                triggerName: 'ecommerceProductPurchased',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.ecommerceCategoryPurchased.title'),
                description: 'Invia una serie di messaggi quando viene acquistato un prodotto di una specifica categoria',
                type: 'ecommerce',
                triggerName: 'ecommerceCategoryPurchased',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.ecommerceBrandPurchased.title'),
                description: 'Invia una serie di messaggi quando viene acquistato un prodotto di uno specifico brand',
                type: 'ecommerce',
                triggerName: 'ecommerceBrandPurchased',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.ecommerceAbbandonedCart.title'),
                description: 'Invia una serie di messaggi quando viene abbandonato un carrello',
                type: 'ecommerce',
                triggerName: 'ecommerceAbbandonedCart',
            },
            {
                title: this.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.title'),
                description: 'Invia una serie di messaggi in base allo storico attività di un contatto',
                type: 'ecommerce',
                triggerName: 'ecommerceActivityHistory',
            },
        ]
    }

    hasTriggerRule (type: string) {
        if (this.user.configuration.rules.automationTriggerAvailable.length === 0) return false
        if (this.user.configuration.rules.automationTriggerAvailable[0] === '*') return true
        return this.user.configuration.rules.automationTriggerAvailable.includes(type)
    }

    getIconName (flowType: string) {
        switch (flowType) {
            case 'campaign': return 'mail'
            case 'integration': return 'code'
            case 'date': return 'calendar-select-day'
            case 'ecommerce': return 'shopping-cart'
            case 'list': return 'list'
            case 'workflow': return 'gears'
        }
    }

    getBadgeColor (flowType: string) {
        switch (flowType) {
            case 'campaign': return 'primary'
            case 'integration': return 'grey'
            case 'date': return 'info'
            case 'ecommerce': return 'warning'
            case 'list': return 'success'
            case 'workflow': return 'alert'
        }
    }

    getFlowType (flowType: string) {
        switch (flowType) {
            case 'campaign': return 'Attività di campagna'
            case 'integration': return 'Integrazioni'
            case 'date': return 'Date di calendario'
            case 'inbound': return 'Inbound marketing'
            case 'ecommerce': return 'E-commerce'
            case 'list': return 'Attività di lista'
            case 'workflow': return 'Attività automation'
        }
    }

    public openModal () {
        this.selectedList = null
        this.listId = null
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    async getLists (search: string) {
        try {
            const resp = await getLists(
                {
                    search: search.trim() !== '' ? `name:${search}` : '',
                    searchFields: search.trim() !== '' ? 'name:like' : '',
                    earchJoin: 'and',
                    page: 1,
                    limit: 100,
                    include: 'store',
                    orderBy: 'id',
                    sortedBy: 'desc',
                },
            )
            this.lists = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private async createAutomation (triggerName: string) {
        this.loading = true
        try {
            const resp = await createWorkflow({
                name: 'Automation senza nome',
                trigger_name: triggerName,
                recipient_id: this.listId,
            })
            this.$emit('workflow-created', resp.data)
            this.closeModal()
        } catch (e) {
            console.log(e)
            let message = ''
            const errorMessage = get(e, 'response.data.message', '')
            if (errorMessage === 'You\'ve reached the limit of workflows in your account') {
                message = 'Hai raggiunto il limite massimo di Marketing automation creabili'
            }

            if (errorMessage === 'You cannot create this kind of workflow') {
                message = 'Non puoi creare questa tipologia di Marketing automation'
            }

            this.$root.$vsToast({
                heading: 'Errore durante la creazione del flusso',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    formatBadgeLabel () {
        return this.selectedList?.name || this.listId
    }

    setListId (listId: any) {
        const list = this.lists.find(el => el.id === listId) || null
        this.listId = listId
        this.selectedList = list
    }
}
