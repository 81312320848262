var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workflow)?_c('VsListingCard',{attrs:{"aspect":(this.value && this.value.indexOf(_vm.workflow.id) > -1) ? 'primary-100' : 'white'},scopedSlots:_vm._u([{key:"accessories",fn:function(){return [(_vm.workflow.status)?_c('VsBadge',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-mr-2 vs-mb-2 vs-align-top",attrs:{"aspect":_vm.badgeStatusAspect,"variant":"icon-left","title":_vm.$t('workflows.workflowCard.status.' + _vm.workflow.status),"iconName":_vm.badgeStatusIcon}},[_c('span',{staticClass:"vs-hidden md:vs-inline-flex"},[_vm._v(" "+_vm._s(_vm.$t('workflows.workflowCard.status.' + _vm.workflow.status))+" ")])]):_vm._e(),_c('span',{staticClass:"vs-mr-2 vs-mb-2 vs-body-small vs-whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.workflow.created_at))+" ")])]},proxy:true},{key:"heading",fn:function(){return [_c('router-link',{staticClass:"vs-no-underline vs-h400 vs-align-middle vs-cursor-pointer vs-text-grey-900 vs-truncate",attrs:{"to":{
                name: 'editWorkflow',
                params: {
                    workflowId: _vm.workflow.id,
                }
            }}},[_vm._v(" "+_vm._s(_vm.workflow.name)+" ")]),_c('span',[_c('VsIcon',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-align-middle vs-flex-none",attrs:{"content":("\n                    ID: " + (_vm.workflow.id) + "<br>\n                "),"size":"4","name":"info-filled","color":"grey-600"}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"vs-body-medium"},[_vm._v(" Trigger: "),_c('b',[_vm._v(_vm._s(_vm.$te(("workflows.workflowCard.triggerName." + (_vm.workflow.trigger_name) + ".title")) ? _vm.$t(("workflows.workflowCard.triggerName." + (_vm.workflow.trigger_name) + ".title")) : 'Deprecato'))])]),_c('div',{staticClass:"vs-body-medium"},[_vm._v(" Lista: "),_c('b',{class:{'vs-text-alert-500': !_vm.workflow.recipient}},[_vm._v(_vm._s(_vm.workflow && _vm.workflow.recipient && _vm.workflow.recipient.data && _vm.workflow.recipient.data.name || 'non trovata'))])])]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"vs-flex vs-items-center vs-gap-2"},[(_vm.workflow.status !== 'ready')?_c('VsButton',{staticClass:"vs-hidden md:vs-inline-flex",attrs:{"aspect":"secondary","to":{
                    name: 'editWorkflow',
                    params: {
                        workflowId: _vm.workflow.id,
                    }
                }}},[_vm._v(" Modifica ")]):_vm._e(),(_vm.workflow.status === 'ready')?_c('VsButton',{staticClass:"vs-hidden md:vs-inline-flex",attrs:{"aspect":"secondary","to":{
                    name: 'workflowStatistics',
                    params: {
                        workflowId: _vm.workflow.id,
                    }
                }}},[_vm._v(" Guarda statistiche ")]):_vm._e(),_c('VsDropdownButton',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"size":"medium","title":"Altre azioni"}},[_c('VsActionItem',{class:{'md:vs-hidden' : _vm.workflow.status !== 'ready'},attrs:{"label":'Modifica',"to":{
                        name: 'editWorkflow',
                        params: {
                            workflowId: _vm.workflow.id,
                        }
                    }}}),_c('VsActionItem',{class:{'md:vs-hidden' : _vm.workflow.status === 'ready'},attrs:{"label":'Guarda statistiche',"to":{
                        name: 'workflowStatistics',
                        params: {
                            workflowId: _vm.workflow.id,
                        }
                    }}}),_c('VsActionItem',{attrs:{"label":'Coda di invio',"to":{
                        name: 'workflowQueue',
                        params: {
                            workflowId: _vm.workflow.id,
                        }
                    }}}),_c('VsActionItem',{attrs:{"aspect":"alert","label":'Elimina'},on:{"click":function($event){return _vm.$emit('delete')}}})],1)],1)]},proxy:true}],null,false,4049130371)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }