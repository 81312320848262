




















































































































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsLink from '@advision/vision/src/components/utilities/VsLink/Index.vue'

@Component({
    name: 'VsWorkflowCard',
    components: {
        VsListingCard,
        VsLink,
        VsDropdownButton,
        VsImage,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private workflow!: any

    @Prop({
        default: null,
        required: false,
    }) private value!: string[]

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get badgeStatusAspect () {
        switch (this.workflow?.status || null) {
            case 'ready':
                return 'success'
            case 'pause':
                return 'warning'
            case 'draft':
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.workflow?.status) return ''
        switch (this.workflow?.status || null) {
            case 'pause':
                return 'pause'
            case 'ready':
                return 'double-check'
            case 'draft':
                return 'edit'
        }
    }

    private redirectToUniversity () {
        window.open(`${this.universityUrl}/article/337-la-mia-campagna-e-stata-messa-in-pausa`, '_blank')
    }

    private redirectToStatistics (workflowId: string) {
        this.$router.push({
            name: 'workflowStatistics',
            params: {
                workflowId,
            },
        })
    }
}
